export const getToken = async (msalInstance, userAccount) => {
  const request = {
    scopes: [process.env.REACT_APP_AZURE_BACKEND_DEV],
    account: userAccount
  };
  try {
    return await msalInstance.acquireTokenSilent(request);
  } catch (error) {
    throw new Error(`Could not get user token: ${error}`);
  }
};
