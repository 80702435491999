import React, { useState } from "react";
import "./application-table.css"
import Button from "react-bootstrap/Button";

function AddApplication({ handleShowAddAppModal }) {

  return (
    <div className="col-md-4 custom-div" >
      <h2 className="search-text">Add app:</h2>
      <Button className="custom-btn" style={{ width: 'auto' }} onClick={() => handleShowAddAppModal()} >
        Add application
      </Button>
    </div >
  );
}

export default AddApplication;