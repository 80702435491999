import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { useMsal } from '@azure/msal-react';
import { getToken } from '../../msal/getToken';
import { modifyApplication } from "../../api/applications"

const EditModal = ({ application, show, setShowEditModal, fetchApp }) => {
  const { instance, accounts } = useMsal();
  const [newAppName, setNewAppName] = useState(application.name)

  const handleClose = () => {
    setShowEditModal(false);
  }
  const handleSave = async () => {
    handleClose();
    let response = null;
    const userToken = await getToken(instance, accounts[0]);
    if (newAppName !== application.name) {
      response = await modifyApplication(userToken, application.id, newAppName, application.ownerEmail)
      if (response.ok) {
        fetchApp();
      }
      else alert(`Could not save the changes, ${response.message} `);
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title >Edit: {application.name} </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ alignItems: 'center', justifyContent: 'center' }}>
          <div>
            <h5 style={{ marginRight: '20px' }}>Change application name: </h5>
            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default">New name</InputGroup.Text>
              <FormControl
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                value={newAppName}
                onChange={(e) => setNewAppName(e.currentTarget.value)}
              />
            </InputGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{ backgroundColor: '#2675a0' }} onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditModal;