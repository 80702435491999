import React from 'react';
import { useState, useEffect, useRef } from 'react';
import "./logging-table.css";
import { getLogs } from "../../api/logs"
import { VariableSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { getToken } from '../../msal/getToken';
import SliderForm from '../../components/sliderform';
import { getApplications } from "../../api/applications"
import LoadingSpinner from '../../components/spinner';

const LoggingTable = () => {
  const { instance, accounts } = useMsal();
  const [callApi, setCallApi] = useState(true);
  const [timeInterval, setTimeInterval] = useState(5);
  const entries = 500;
  const interval = useRef(null);
  const [logs, setLogs] = useState([]);
  const { id } = useParams();
  const [apps, setApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // TODO: Implement error and a modal for displaying error to user?
  // const [error, setError] = useState([]);

  useEffect(() => {
    // A way to call async functions in useEffect
    (async () => {
      setIsLoading(true);
      await fetchApps();
      await fetchLogs();
      setIsLoading(false);
    })();
    interval.current = setInterval(async () => {
      if (callApi) {
        await fetchLogs();
      }
    }, timeInterval * 1000);

    return () => clearInterval(interval.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLogs = async () => {
    const userToken = await getToken(instance, accounts[0]);
    const fetchedLogs = await getLogs(userToken, id, entries);
    if (fetchedLogs) {
      setLogs([...fetchedLogs]);
    }
    else {
      console.log("could not fetch logs from api")
    }
  }
  const fetchApps = async () => {
    const userToken = await getToken(instance, accounts[0]);
    const fetchedApps = await getApplications(userToken);
    if (fetchedApps) {
      setApps([...fetchedApps]);
    }
    else throw new Error("Couldn't fetch apps from api")
  }

  // Used when changing interval for api call. ClearInterval cancel current timer-function
  const resetInterval = (intervall) => {
    clearInterval(interval.current);
    interval.current = setInterval(async () => {
      await fetchLogs();
    }, intervall * 1000);
  }
  //Start or stop call to api
  const handleClick = async () => {
    if (callApi) {
      clearInterval(interval.current);
    }
    else {
      resetInterval(timeInterval);
    }
    setCallApi(callBool => !callBool);
  }
  
  // See react-window for documentation. Divs are used to get blod text
  // TODO: Look at other ways to display logs where we are in more control?
  const Row = ({ index, style }) => (
    <div className="ListItemOdd" style={style}>
      {logs.slice(0).reverse().map((log) =>
        <div style={{ display: 'flex' }}>
          <div style={{ fontWeight: "bold" }}> {'LogLevel [' + log.logLevel + ']: ' + log.loggedAt.substr(0, 19).replace("T", " ") + ': '+ log.logMessage} </div>
        </div>
      )}
    </div >
  );

  // Set size of rows based on length of log message. Not been tested properly
  let getItemSize = index => {
    if (logs[index].logMessage.length > 180) {
      return 160;
    }
    else {
      return 120;
    }
  }

  const ListRender = () => (
    <AutoSizer>
      {({ height, width }) => (
        <List
          className="List"
          height={height}
          itemCount={1}
          // itemSize={100} // Uncomment if you want fixed row size
          itemSize={getItemSize}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );

  const renderHeader = () => {
    const header = ["The logs for application: " + apps.filter(app => app.id == id)[0].name];
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };

  const intervalChange = (newInterval) => {
    setTimeInterval(newInterval);
    resetInterval(newInterval);
  }

  return (
    <>
      {isLoading ? (
        LoadingSpinner()
      ) :
        (
          <div className="dynamic-window" >
            <div className="row align-items-center mt-3 mb-3 m-1 ">
              {callApi ? (
                <Button className="custom-btn-log col-md-3" size="lg" onClick={handleClick}>
                  Pause log display
                </Button>) :
                <Button className="custom-btn-log col-md-3" size="lg" onClick={handleClick}>
                  Resume log display
                </Button>}
              <SliderForm intervalChange={intervalChange} initialTimeInterval={timeInterval} />
            </div>
            <table id='applicationState'>
              <thead className="custom-thead">
                <tr >{renderHeader()}</tr>
              </thead>
            </table>
            {ListRender()}
          </div>)}
    </>
  );
}

export default LoggingTable;