import React, { useState } from "react";
import "./application-table.css"

function GlobalFilter({ handleSearch }) {
  const [value, setValue] = useState();

  return (
    <div className="col-md-4 custom-div">
      <h2 className="search-text">Search:</h2>
      <input className="custom-input" value={value || ""} onChange={(e) => {
        setValue(e.target.value);
        handleSearch(e);
      }}
        placeholder={`applications...`}
      />
    </div>
  );
}

export default GlobalFilter;