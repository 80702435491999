import React from 'react';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import AppRouter from "./pages/router-layout";

const App = () => (
  <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
    <AppRouter />
  </MsalAuthenticationTemplate>
);

export default App;