export const APPLICATION_ENDPOINT = `${process.env.REACT_APP_BACKEND_API}/api/log/application/`;

export const getLogs = async (token = null, appId, entries) => {
  // Timeout request after 5 sec
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), 5000)

  const bearer = `Bearer ${token?.accessToken}`;
  const response = await fetch(`${APPLICATION_ENDPOINT}${appId}?entries=${entries} `,
    {
      signal: controller.signal,
      headers: { 'Access-Control-Allow-Origin': '*', Authorization: bearer },
      mode: 'cors',
    })
  if (response.ok) {
    return response.json();
  }
  else throw new Error('Could not get the logs from the api')
}