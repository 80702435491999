import "./main-page.css";
import Header from "./header";
import LoggingTable from "../logging-table";
import ApplicationTable from "../application-table";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function AppRouter() {

  return (
    <Router>
      <div className="container">
        <Header subtitle="Logger for applications" />
        <Routes>
          <Route exact path="/:id" element={<LoggingTable />} />
          <Route exact path="/" element={<ApplicationTable />} />
        </Routes>
      </div>
    </Router>
  );
}

export default AppRouter;
