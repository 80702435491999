import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const SliderForm = ({ intervalChange }) => {
  const [interval, setInterval] = useState(5);

  const onChange = (e) => {
    setInterval(e.target.value);
    intervalChange(Number(e.target.value));
  }

  return (
    <Form style={{ width: 'auto', marginLeft: '20px' }}>
      <Row >
        <Col style={{ textAlign: 'center' }} xs={10}  >
          <Form.Label style={{ textAlign: 'center', fontWeight: 'bold' }}> Interval for api call: {interval} seconds</Form.Label>
        </Col>
        <Col xs={10} >
          <Form.Range
            value={interval}
            step='0.5'
            min='0.5'
            max='10'
            onChange={onChange}
          />
        </Col>
      </Row>
    </Form >
  );
}

export default SliderForm;