import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ToggleButton from 'react-bootstrap/ToggleButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { useMsal } from '@azure/msal-react';
import { getToken } from '../../msal/getToken';
import { startApplication, stopApplication, modifyApplication } from "../../api/applications"

const ManageModal = ({ application, show, setShowManageModal, fetchApp }) => {
  const { instance, accounts } = useMsal();
  const [newAppName, setNewAppName] = useState(application.name)

  const handleClose = () => {
    setShowManageModal(false);
  }
  const handleSave = async () => {
    handleClose();
    let response = null;
    const userToken = await getToken(instance, accounts[0]);

    // Handle change in state
    if (activeStates[stateValue - 1].name === "Active") {
      if (!application.isActive) {
        application.isActive = true;
        response = await startApplication(userToken, application.id);
      }
    }
    else {
      if (application.isActive) {
        application.isActive = false;
        response = await stopApplication(userToken, application.id);
      }
    }
    // Handle in name
    if (newAppName !== application.name) {
      response = await modifyApplication(userToken, application.id, newAppName, application.ownerEmail)
    }

    if (response.ok) {
      fetchApp();
    }
    else alert(`Could not save the changes, ${response.message} `);
  }

  const [stateValue, setStateValue] = useState(application.isActive === true ? '1' : '2');
  const activeStates = [
    { name: 'Active', value: '1' },
    { name: 'Deactive', value: '2' },
  ]

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title >Manage: {application.name} </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ alignItems: 'center', justifyContent: 'center' }}>
          <h5 style={{ marginRight: '20px' }}>Set the state: </h5>
          <ButtonGroup className="mb-4" style={{ display: 'flex' }}>
            {activeStates.map((state, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={idx === 0 ? 'outline-success' : 'outline-danger'}
                name="radio"
                value={state.value}
                checked={stateValue === state.value}
                onChange={(e) => setStateValue(e.currentTarget.value)}
              >
                {state.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{ backgroundColor: '#2675a0' }} onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ManageModal;