import react from 'react';
import { useState, useEffect, useRef } from 'react'
import Table from 'react-bootstrap/Table';
import { getApplications } from "../../api/applications"
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import GlobalFilter from './globalFilter';
import AddApplication from './addApplication';
import AddModal from './addModal';
import EditModal from './editModal';
import ManageModal from './manageModal';
import { useMsal } from '@azure/msal-react';
import { getToken } from '../../msal/getToken';
import "./application-table.css";
import LoadingSpinner from '../../components/spinner';


const ApplicationTable = () => {
  const { instance, accounts } = useMsal();
  const [showManageModal, setShowManageModal] = useState(false);
  const [showAddAppModal, setShowAddAppModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [manageId, setManageId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [apps, setApps] = useState([]);
  const [filteredApps, setFilteredApps] = useState();
  const [isFiltering, setIsFiltering] = useState(false);
  let navigate = useNavigate();

  const fetchApplications = async () => {
    setIsLoading(true);
    const userToken = await getToken(instance, accounts[0]);
    const response = await getApplications(userToken);
    if (response) {
      setApps(response);
    }
    setIsLoading(false);
  }
  useEffect(async () => {
    setIsLoading(true);
    await fetchApplications();
    setFilteredApps(apps);
    setIsLoading(false)
  }, []);


  const handleClick = (appId) => {
    navigate(`/${appId}`);
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 0) {
      setIsFiltering(true);
    }
    else setIsFiltering(false);
    let value = e.target.value.toLowerCase();
    let currentApps = [...apps];
    const result = [];
    currentApps.forEach(app => {
      if (app.name.toLowerCase().includes(value) || app.isActive.toString().toLowerCase().includes(value)) {
        result.push(app)
      }
    })
    setFilteredApps(result);
    console.log(filteredApps);
  }

  const handleShowAddAppModal = () => {
    setShowAddAppModal(true);
  }

  const handleShowManage = (id) => {
    setShowManageModal(true);
    setManageId(id);
  }
  const handleShowEdit = (id) => {
    setShowEditModal(true);
    setManageId(id);
  }

  return (
    <>
      {showManageModal ? (
        <ManageModal application={apps.find(elem => elem.id === manageId)} show={showManageModal} setShowManageModal={setShowManageModal} fetchApp={fetchApplications} />) : ""}
      {showAddAppModal ? (
        <AddModal show={showAddAppModal} setShowAddAppModal={setShowAddAppModal} fetchApp={fetchApplications} > </AddModal>) : ""}
      {showEditModal ? (
        <EditModal application={apps.find(elem => elem.id === manageId)} show={showEditModal} setShowEditModal={setShowEditModal} fetchApp={fetchApplications}> </EditModal>) : ""}
      <div className="row align-items-center">
        <GlobalFilter handleSearch={handleSearch} />
      </div>
      <div className="row align-items-center mb-2">
        <AddApplication handleShowAddAppModal={handleShowAddAppModal} />
      </div>
      {isLoading ? (
        LoadingSpinner()
      ) :
        (
          <>
            <Table striped bordered hover style={{ verticalAlign: 'middle', textAlign: 'center' }} >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Application name</th>
                  <th className="col-md-2">Is active</th>
                  <th className="col-md-2" >Actions</th>
                </tr>
              </thead>
              <tbody>
                {!isFiltering ? apps.map(app => (
                  <tr key={app.id} >
                    <td onClick={() => handleClick(app.id)} style={{ cursor: 'pointer' }}>{app.id}</td>
                    <td onClick={() => handleClick(app.id)} style={{ cursor: 'pointer' }}>{app.name}</td>
                    <td onClick={() => handleClick(app.id)} style={{ cursor: 'pointer', backgroundColor: app.isActive.toString() === 'true' ? 'rgba(40,167,69, 0.3)' : 'rgba(255, 0, 0, 0.3)' }}>{app.isActive.toString()}</td>
                    <td style={{ display: 'flex' }}><Button className="custom-btn" onClick={() => handleShowManage(app.id)}>
                      Manage
                    </Button>
                      {!isLoading && (app.ownerEmail !== null && (<><span>
                        <Button className="custom-btn" style={{ marginLeft: '1rem' }} onClick={() => handleShowEdit(app.id)}>
                          Edit
                        </Button> </span></>))}

                    </td>
                  </tr>
                )) :
                  filteredApps.map(app => (
                    <tr key={app.id} >
                      <td onClick={() => handleClick(app.id)} style={{ cursor: 'pointer' }}>{app.id}</td>
                      <td onClick={() => handleClick(app.id)} style={{ cursor: 'pointer' }}>{app.name}</td>
                      <td onClick={() => handleClick(app.id)} style={{ cursor: 'pointer', backgroundColor: app.isActive.toString() === 'true' ? 'rgba(40,167,69, 0.3)' : 'rgba(255, 0, 0, 0.3)' }}>{app.isActive.toString()}</td>
                      <td style={{ display: 'flex' }}><Button className="custom-btn" onClick={() => handleShowManage(app.id)}>
                        Manage
                      </Button>
                        {!isLoading && (app.ownerEmail !== null && (<><span>
                          <Button className="custom-btn" style={{ marginLeft: '1rem' }} onClick={() => handleShowEdit(app.id)}>
                            Edit
                          </Button> </span></>))}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </>
        )
      }
    </>
  )
}

export default ApplicationTable;