export const msalConfig = {
  auth: {
    // clientId: "c107aa2f-af8a-4d65-ac35-a9c129f6f61b",
    // authority: "https://login.microsoftonline.com/35de1f6f-7463-4230-b310-c6161e75518a",
    // redirectUri: "http://localhost:3000"
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read']
  // scopes: [`api://87afac81-ee3d-4f73-9aa0-c0c51434f869/user_impersonation`],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};
