export const APPLICATION_ENDPOINT = `${process.env.REACT_APP_BACKEND_API}/api`;

export const getApplications = async (token = null) => {
  const bearer = `Bearer ${token?.accessToken}`;
  const response = await fetch(`${APPLICATION_ENDPOINT}/admin/application/list`, {
    headers: { 'Access-Control-Allow-Origin': '*', Authorization: bearer },
    mode: 'cors',
  })
  if (response.ok) {
    return response.json();
  }
  else throw new Error('Could not get the applications')
}

export const addApplication = async (token = null, name) => {
  let newApp = {
    Name: name,
  };
  const bearer = `Bearer ${token?.accessToken}`;
  const results = await fetch(
    `${APPLICATION_ENDPOINT}/register/application/create`,
    {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      mode: 'cors',
      body: JSON.stringify(newApp),
    }
  );
  return results
};

export const modifyApplication = async (token = null, applicationId, newName, owner = null) => {
  let appObject = {
    Name: newName,
    ownerEmail: owner
  };
  const bearer = `Bearer ${token?.accessToken}`;
  const results = await fetch(
    `${APPLICATION_ENDPOINT}/admin/application/${applicationId}/modify`,
    {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      mode: 'cors',
      body: JSON.stringify(appObject),
    }
  );
  return results
};

export const startApplication = async (token = null, applicationId) => {
  const bearer = `Bearer ${token?.accessToken}`;
  const results = await fetch(
    `${APPLICATION_ENDPOINT}/manage/application/${applicationId}/start`,
    {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      mode: 'cors',
      // body: JSON.stringify(application),
    }
  );
  if (results.ok) {
    return results
  }
  throw new Error('Could not start the application');
};

export const stopApplication = async (token = null, applicationId) => {
  const bearer = `Bearer ${token?.accessToken}`;
  const results = await fetch(
    `${APPLICATION_ENDPOINT}/manage/application/${applicationId}/stop`,
    {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      mode: 'cors',
      // body: JSON.stringify(application),
    }
  );
  return results
};
