import logo from "./AE.png";
import { useNavigate } from "react-router-dom";

const Header = ({ subtitle }) => {
  let navigate = useNavigate();

  return (
    <>
      <header className="row mt-5 mb-5 align-items-center">
        <div className="col-md-5">
          <img src={logo} className="logo" alt="logo" onClick={() => navigate(`/`)} style={{ cursor: 'pointer' }} />
        </div>
        <div className="col-md-7 subtitle">{subtitle}</div>
      </header>
    </>
  )
}

export default Header;
