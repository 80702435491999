import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { useMsal } from '@azure/msal-react';
import { getToken } from '../../msal/getToken';
import { addApplication } from "../../api/applications"

const AddModal = ({ show, setShowAddAppModal, fetchApp }) => {
  const { instance, accounts } = useMsal();
  const [appName, setAppName] = useState("");

  const handleClose = () => {
    setShowAddAppModal(false);
  }

  const handleSave = async () => {
    handleClose();
    const userToken = await getToken(instance, accounts[0]);
    if (appName.length > 0) {
      const response = await addApplication(userToken, appName)
      if (response.ok) {
        fetchApp();
      }
      else if (response.status === 409) {
        alert("Could not add the application - The name already exist")
      }
      else {
        alert("Could not add the application")
      }
    }
    else {
      alert("Name is empty, did not add the application!")
    }

  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title >Add application </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ alignItems: 'center', justifyContent: 'center' }}>
          <h5 style={{ marginRight: '20px' }}>Set name of application </h5>
          <InputGroup className="mb-3">
            <InputGroup.Text id="inputGroup-sizing-default">Name</InputGroup.Text>
            <FormControl
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              value={appName}
              onChange={(e) => setAppName(e.currentTarget.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{ backgroundColor: '#2675a0' }} onClick={handleSave}>
            Add application
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddModal;